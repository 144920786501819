export const toPlainString = (num) => {
  return ("" + +num).replace(
    /(-?)(\d*)\.?(\d*)e([+-]\d+)/,
    function (a, b, c, d, e) {
      return e < 0
        ? b + "0." + Array(1 - e - c.length).join(0) + c + d
        : b + c + d + Array(e - d.length + 1).join(0);
    }
  );
};

export const cutAfterDecimal = (number, pos, dl, ac) => {
  if (Number(number)) {
    if (dl) {
      const limit = dl?.decimalLimit[ac] > 0 ? dl?.decimalLimit[ac] : 5;
      const res =
        number?.toString()?.indexOf(".") > -1
          ? number
              .toString()
              .slice(0, number.toString().indexOf(".") + limit + 1)
          : number;
      return res;
    } else {
      const res =
        number?.toString()?.indexOf(".") > -1
          ? number.toString().slice(0, number.toString().indexOf(".") + pos + 1)
          : number;
      return res;
    }
  } else {
    return 0;
  }
};
