import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import LogoBigTyLight from "./../assets/images/bigtycoon_images/BigTycoonlogo.png";

function Header() {
  const [headerFix, setheaderFix] = React.useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);

  useEffect(() => {
    if (sidebarOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [sidebarOpen]);

  return (
    <>
      <header className="site-header mo-left header header-transparent style-1">
        <div
          className={`sticky-header main-bar-wraper navbar-expand-lg ${headerFix ? "is-fixed" : ""
            }`}
        >
          <div className="main-bar clearfix">
            <div className="container clearfix">
              <div className="logo-header">
                <Link to={"/"} className="logo-dark">
                  <img src={LogoBigTyLight} alt="Image not found." />
                </Link>
                <Link to={"/"} className="logo-light">
                  <img src={LogoBigTyLight} alt="Image not found." />
                </Link>
              </div>

              <button
                type="button"
                className={`navbar-toggler  navicon justify-content-end ${sidebarOpen ? "open" : "collapsed"
                  }`}
                onClick={() => setSidebarOpen(!sidebarOpen)}
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
              <div
                className={`header-nav navbar-collapse collapse justify-content-end ${sidebarOpen ? "show" : ""
                  }`}
                id="navbarNavDropdown"
              >
                <div className="logo-header mostion">

                  <NavLink to={"#"} className="logo-dark">
                    <img
                      src={LogoBigTyLight}
                      alt="Image not found."
                      style={{
                        height: "100px",
                        with: "100px",
                        marginTop: "-35px"
                      }}
                    />
                  </NavLink>

                </div>
                <ul className="nav navbar-nav navbar">
                  <li>
                   <span className="text-info">Offical Twitter - </span> 
                    <a target="_blank" href={"https://twitter.com/WiseBlockchainX"}>
                      <u>WiseBlockchainX</u>
                    </a>
                  </li>
                  <li  >

                <span className="text-info">  Contact us -</span>
                    <a href="mailto:info@bigtycoon.co" className="text">
                      <u>info@bigtycoon.co</u>
                    </a>
                  </li>

                  <li>
                    <a target="_blank" href={"https://bigtycoon.io/register"}>
                      <u>Buy Now</u>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href={"/btyc_WhitePaper.pdf"}>
                      <u>Whitepaper</u>
                    </a>
                  </li>
                  <li
                    className={`sub-menu-down ${showMenu ? "open" : ""}`}
                    id="menushow"
                    onClick={() => setShowMenu(!showMenu)}
                  ></li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
export default Header;
