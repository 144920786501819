import React, { useEffect, useState } from "react";
import coin1 from "./../../assets/images/coins/coin1.png";
import coin3 from "./../../assets/images/coins/coin3.png";
import coin4 from "./../../assets/images/coins/coin4.png";
import axios from "axios";
import { apiBaseUrl } from "../../constants/constant";
import { toPlainString, cutAfterDecimal } from "../../HelperFunctions/Helper";

function BannerCard() {
  const [ethereumData, setEthereumData] = useState([]);
  const [bitcoinData, setBitcoinData] = useState([]);
  const [tetherData, setTetherData] = useState([]);

  useEffect(() => {
    getCoinsData();
  }, []);

  const getCoinsData = async () => {
    try {
      const responseBTC = await axios.get(`${apiBaseUrl}/coin_data_btc`);
      const responseETH = await axios.get(`${apiBaseUrl}/coin_data_eth`);
      const responseUSDT = await axios.get(`${apiBaseUrl}/coin_data_usdt`);

      setEthereumData(responseETH?.data?.data);
      setBitcoinData(responseBTC?.data?.data);
      setTetherData(responseUSDT?.data?.data);
    } catch (err) {
      console.log(err, ":::");
    }
  };

  const cardData = [
    {
      image: coin4,
      title: "Bitcoin",
      subtitle: "BTYC",
      price: bitcoinData?.BTYC?.quote?.BTC?.price
        ? cutAfterDecimal(
            toPlainString(bitcoinData?.BTYC?.quote?.BTC?.price),
            10
          ) +
          " " +
          "BTC"
        : 0,
      percent:
        bitcoinData?.BTYC?.quote?.BTC?.percent_change_24h.toFixed(2) || null
    },
    {
      image: coin3,
      title: "Ethereum",
      subtitle: "BTYC",
      price: ethereumData?.BTYC?.quote?.ETH?.price
        ? cutAfterDecimal(
            toPlainString(ethereumData?.BTYC?.quote?.ETH?.price),
            10
          ) +
          " " +
          "ETH"
        : 0,
      percent:
        ethereumData?.BTYC?.quote?.ETH?.percent_change_24h.toFixed(2) || null
    },
    {
      image: coin1,
      title: "Tether",
      subtitle: "BTYC",
      price: tetherData?.BTYC?.quote?.USDT?.price
        ? cutAfterDecimal(
            toPlainString(tetherData?.BTYC?.quote?.USDT?.price),
            10
          ) +
          " " +
          "USDT"
        : 0,
      percent:
        tetherData?.BTYC?.quote?.USDT?.percent_change_24h.toFixed(2) || null
    }
  ];

  return (
    <>
      {cardData.map((data, index) => (
        <div
          className="col-lg-4 col-md-6 m-b30 wow fadeInUp"
          data-wow-delay="0.2s"
          key={index}
        >
          <div className="icon-bx-wraper style-1 box-hover">
            <div className="icon-media">
              <img src={data.image} alt="" />
              <div className="icon-info">
                <h5 className="title">{data.title}</h5>
                <span>{data.subtitle}</span>
              </div>
            </div>
            <div className="icon-content">
              <ul className="price">
                <li>
                  <h6 className="mb-0 amount">{data.price}</h6>
                  <span
                    className={`percentage ${
                      data.percent > 0 ? "text-green" : "text-red"
                    }`}
                  >
                    {data.percent}%
                  </span>
                </li>
                <li>
                  <span>Latest price</span>
                  <span>24h change</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default BannerCard;
