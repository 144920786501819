import React from "react";
import Route from "./pages/route";
import "./assets/css/style.css";

function App() {
  return (
    <div className="App">
      <Route />
    </div>
  );
}

export default App;
