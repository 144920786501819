import React from "react";
import { Link } from "react-router-dom";

//Images
import Icon9 from "./../../assets/images/icons/icon9.svg";
import Icon10 from "./../../assets/images/icons/icon10.svg";
import Icon11 from "./../../assets/images/icons/icon11.svg";
import Icon12 from "./../../assets/images/icons/icon12.svg";
import Icon13 from "./../../assets/images/icons/icon13.svg";
import Icon14 from "./../../assets/images/icons/developement2.png";
import pic1 from "./../../assets/images/about/pic1.jpg";
import support1 from "./../../assets/images/icons/support1.png";

const cardData = [
  {
    image: Icon9,
    title: "Purchase BTYC",
    description:
      "Visit  Pancakeswap or Coinsbit exchange that meets your needs, is trustworthy and safe, and is compatible with your preferences purchase required investment BTYC"
  },
  {
    image: Icon10,
    title: "Register With BTYC Stacking Program",
    description:
      "Next to visit bigtycoon.io go to auth section register with your referral in case of no referral you will join directly to the system"
  },
  //   {
  //     image: Icon11,
  //     title: "Quick delivery of the domain",
  //     description:
  //       "In most cases access to the domain will be available within one to two hours of purchase, however access to domains purchased after business hours will be available within the next business day."
  //   },
  {
    image: Icon12,
    title: "Stacking Reward",
    description:
      "After stacking you will get stacking bonus as reward acccording to investment plan that you have joined."
  },
  {
    image: Icon13,
    title: "Level Bonus",
    description:
      "If you will be successfully register to BigTycoon stacking program you will get a referral link that you can share to your community on every joining from your referrals you will get level bonus"
  },
  {
    image: Icon14,
    title: "Fast Withdrawal",
    description:
      "Level bonus distribution directly to your wallet instantly, Stacking bonus reward can claimable anytime over the dashboard."
  }
];

function OneStop() {
  return (
    <>
      {cardData.map((item, ind) => (
        <div className="col-xl-4 col-md-6 m-b60" key={ind}>
          <div className="icon-bx-wraper style-3 text-center">
            <div className="icon-media">
              <img src={item.image} alt="" />
            </div>
            <div className="icon-content">
              <h4 className="title">{item.title}</h4>
              <p className="m-b0">{item?.description}</p>
            </div>
          </div>
        </div>
      ))}
      {/* <div className="col-xl-4 col-md-6 m-b60">
        <div
          className="icon-bx-wraper style-4"
          style={{ backgroundImage: "url(" + pic1 + ")" }}
        >
          <div className="inner-content">
            <div className="icon-media m-b30">
              <img src={support1} alt="" />
            </div>
            <div className="icon-content">
              <Link to={"/contact-us"} className="btn btn-primary">
                Call Us
              </Link>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
export default OneStop;
