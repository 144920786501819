import React, { useRef } from "react";
import { Link } from "react-router-dom";
import BannerCard from "./../components/Home/BannerCard";
import OneStop from "./../components/Home/OneStop";
import banner1 from "./../assets/images/reward-recognition.svg";
import Shape1 from "./../assets/images/home-banner/shape1.png";
import Shape3 from "./../assets/images/home-banner/shape3.png";
import friend from "./../assets/images/icons/friend.svg";
import MetaMask_Fox from "./../assets/images/avatar/MetaMask_Fox.svg.webp";
import coinbase from "./../assets/images/main.png";
// import coinbase from "./../assets/images/avatar/coinbase.jpeg";
import trustwallet from "./../assets/images/avatar/trustwallet.png";
import walletconnect from "./../assets/images/avatar/walletconnect.jpeg";
import walletImg from "./../assets/images/wallet/wallet.bc0e49e4da0c0f41493831eb288692a6 (2).svg";
import Faq from "react-faq-component";
import Roadmap from "./roadmap";
import TenBillion from "../assets/images/tokenomics-list-image-1.764a5d27762096947fc463f445fdd078.svg";
import pieChart from "../assets/images/Asset 4.png";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const Home = () => {
  const codeRef = useRef(null);

  const trustBlog = [
    {
      image: walletImg,
      title: "Why BigTycoon ?",
      description:
        "Investing in digital currency through cash purchases is a safe, discreet, and easily accessible option. Individuals can enjoy the benefits of decentralised finance while preserving ownership of their assets by avoiding the use of conventional banking systems. However, it is essential to do one's homework, select trustworthy platforms, and put one's own safety first while dealing with currency. Adopt this new approach, and venture forward into the bitcoin market with complete assurance."
    },
    {
      image: friend,
      title: "BTYC Defi",
      description:
        "Decentralised Finance, abbreviated as 'DeFi,' is a subset of the bitcoin business that is seeing explosive growth and whose primary objective is to deliver conventional financial services using decentralised platforms. Although lending, borrowing, and trading digital assets are the primary objectives of DeFi, the platform also contains options for conducting transactions in a secure manner."
    }
  ];

  const faqData = {
    title: "FAQ (How it works)",
    rows: [
      {
        title: "What is Blockchain ?",
        content: `A blockchain is a distributed database or ledger
            that is shared among the nodes of a computer
            network. As a database, a blockchain stores
            information electronically in digital format.
            Blockchain are best known for their crucial
            role in cryptocurrency systems, such as
            Bitcoin, for maintaining a secure and
            decentralized record of transactions. The
            innovation with a blockchain is that it
            guarantees the fidelity and security of a record
            of data and generates trust without the need
            for a trusted third party.`
      },
      {
        title: "What is Decentralization ?",
        content:
          "It is a system in which there are no admins, there is no single server or system monitoring, project management. The creators of the platform are the same project participants like you."
      },
      {
        title: "What is Smart Contract ?",
        content: `Smart Contracts, Like Cryptocurrencies, Are Decentralized. They
        Work Strictly According To The Underlying Program, Without
        The Possibility Of Subsequently Chan- Ging The Defined
        Execution. The Code That Contains All The Logic Of The Contract
        Expiration Is On The Blockchain And All Calculations Are
        Provided By Millions Of Computers Around The World.
        This Ensures That There Is No Risk Of Hacking Into The Smart
        Contract And Stopping The Project.`
      },
      {
        title: "What is BigTycoon Technology ?",
        content: `Bitcoin (abbreviation: BTC or XBT; sign: ₿) is a protocol which implements a highly available, public, permanent, and decentralized ledger. In order to add to the ledger, a user must prove they control an entry in the ledger.`
      }
    ]
  };

  const styles = {
    bgColor: "white",
    titleTextColor: "#362465",
    rowTitleColor: "blue",
    rowContentColor: "grey",
    arrowColor: "red"
  };

  const config = {
    animate: true,
    arrowIcon: "V",
    openOnload: 0,
    expandIcon: "+",
    collapseIcon: "-"
  };

  const handleCopy = () => {
    codeRef.current.select();
    document.execCommand("copy");
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 12
    }
  }));
  return (
    <>
      <div className="page-content">
        <div className="main-bnr style-1">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 text-center">
                <h1 className="">
                  BigTycoon, Faster & <br className="mobileview" />
                  Forward Thinking.
                </h1>
                <p className="text text-primary">
                  Big Tycoon is a crypto payment provider, aiming to scale-up
                  traditional finance by on-boarding decentralized tactics
                </p>
                <a
                  
                  href="https://bigtycoon.co/app/login"
                  className="btn space-lg btn-gradient btn-shadow btn-primary "
                >
                  Connect Wallet
                </a>
                <ul className="image-before">
                  <li className="left-img">
                    <img src={banner1} alt="Image not found." />
                  </li>
                  <li className="right-img">
                    <img src={banner1} alt="Image not found" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <img className="bg-shape1" src={Shape1} alt="" />
          <img className="bg-shape2" src={Shape1} alt="" />
          <img className="bg-shape3" src={Shape3} alt="" />
          <img className="bg-shape4" src={Shape3} alt="" />
        </div>
        <div className="clearfix bg-primary-light">
          <div className="container">
            <div className="currancy-wrapper">
              <div className="row justify-content-center">
                <BannerCard />
              </div>
              <div className="row justify-content-center mt-3">
                <div className="col-md-8 col-12">
                  {/* <div class="form-group d-flex">
                    <input
                      type="text"
                      className="form-control disable-text-selection"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      readOnly
                      ref={codeRef}
                      defaultValue="0xD06F42efBA66A1E80Ce64fcE348a4F6314862307"
                    />
                    <BootstrapTooltip title="Click here to copy Contract Address.">
                      <div className="setButton" onClick={handleCopy}>
                        <span className="setSolidButton">Copy</span>
                      </div>
                    </BootstrapTooltip>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="clearfix section-wrapper1 bg-primary-light">
          <div className="container">
            <div className="content-inner-1">
              <div className="section-head text-center">
                <h1 className="title">Why Trust Us?</h1>
                <p>
                  Big Tycoon, a 2022 crypto ecosystem, is safest. Big Tycoon
                  tailors financial and earning opportunities to individual
                  interests.
                </p>
              </div>
              <div className="row">
                {trustBlog.map((data, ind) => (
                  <div className="col-lg-6 m-b30" key={ind}>
                    <div className="icon-bx-wraper style-2">
                      <div className="icon-media">
                        <img src={data.image} alt="" />
                      </div>
                      <div className="icon-content">
                        <h4 className="title">{data.title}</h4>
                        <p>{data?.description}</p>
                        {/* <Link
                          className="btn btn-primary btn-gradient btn-shadow"
                          to={"/about-us"}
                        >
                          Read More
                        </Link> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="container">
            <div className="section-head text-align-center">
              <h1 className="title text-center">Futuristic Projects</h1>
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div className="widget widget_services">
                    <ul>
                      <li>
                        <Link to="#">Blockchain Development</Link>
                      </li>
                      <li>
                        <Link to="#">Decentralized Wallet</Link>
                      </li>
                      <li>
                        <Link to="#">Decentralized Exchange</Link>
                      </li>
                      <li>
                        <Link to="#">Centralized Exchange</Link>
                      </li>
                      <li>
                        <Link to="#">Token Based Gaming Projects</Link>
                      </li>
                      <li>
                        <Link to="#">Phramaceuticals & Nutraceuticals</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div className="widget widget_services">
                    <ul>
                      <li>
                        <Link to="#">E-Commerce Portal</Link>
                      </li>
                      <li>
                        <Link to="#">Travel & Leisure Portal</Link>
                      </li>
                      <li>
                        <Link to="#">Metaverse Based Real State Projects</Link>
                      </li>
                      <li>
                        <Link to="#">Decentralized Banking & Finance</Link>
                      </li>
                      <li>
                        <Link to="#">NFT Platform</Link>
                      </li>
                      <li>
                        <Link to="#">AI Based Projects</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                  <div className="widget widget_services">
                    <ul>
                      <li>
                        <Link to="#">Short Video Apps</Link>
                      </li>
                      <li>
                        <Link to="#">Marketplace </Link>
                      </li>
                      <li>
                        <Link to="#">Organic Farming & Food Industry</Link>
                      </li>
                      <li>
                        <Link to="#">Social Media Platform</Link>
                      </li>
                      <li>
                        <Link to="#">Matrimonial Platform</Link>
                      </li>
                      <li>
                        <Link to="#">Media & Advertising</Link>
                      </li>
                      <li>
                        <Link to="#">IT Services </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container pb-5">
            <div className="content-inner-1">
              <div className="section-head text-align-center">
                <h1 className="title text-center">
                  This is how the BigTycoon
                  <br />
                  system works.{" "}
                </h1>
                <p className="text text-primary ">
                  Big Tycoon introduces the new generation of decentralized
                  finance for the global finance sector. Connecting the whole
                  the different economy at one place. Big Tycoon would be
                  playing a vital role among the furturistic financial
                  operations.
                </p>
              </div>
            </div>
          </div>
          <img className="bg-shape1" src={Shape1} alt="" />
        </section>

        <section className="content-inner bg-light icon-section section-wrapper2">
          <div className="container">
            <div className="section-head text-center">
              <h1 className="title">
                How to Invest in BTYC ?{" "}
                <span className="text-primary"> cryptocurrency </span> with Cash
              </h1>
            </div>
            <div
              className="row sp60"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <OneStop />
            </div>
          </div>
          <img className="bg-shape1" src={Shape1} alt="" />
        </section>

        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-12 col-md-12">
              <h1
                className="mobileSizeHeading pb-2"
                style={{ color: "#362465" }}
              >
                Supported Wallet
              </h1>
              <p>
                Big Tycoon introduces the new generation of decentralized
                finance for the global finance sector. Connecting the whole the
                different economy at one place. Big Tycoon would be playing a
                vital role among the furturistic financial operations
              </p>
            </div>
            <div className="col-xl-2 col-lg-6 col-md-6 col-6 d-flex justify-content-center align-items-center">
              <div className="widget recent-posts-entry">
                <img
                  src={MetaMask_Fox}
                  alt=""
                  style={{ height: "80px", width: "80px" }}
                />
              </div>
            </div>
            <div className="col-xl-2 col-lg-6 col-md-6 col-6 d-flex justify-content-center align-items-center">
              <div className="widget widget_services">
                <img
                  src={coinbase}
                  alt=""
                  style={{ height: "80px", width: "80px" }}
                />{" "}
              </div>
            </div>
            <div className="col-xl-2 col-lg-6 col-md-6 col-6 d-flex justify-content-center align-items-center">
              <div className="widget recent-posts-entry">
                <img
                  src={trustwallet}
                  alt=""
                  style={{ height: "80px", width: "80px" }}
                />{" "}
              </div>
            </div>
            <div className="col-xl-2 col-lg-6 col-md-6 col-6 d-flex justify-content-center align-items-center">
              <div className="widget recent-posts-entry">
                <img
                  src={walletconnect}
                  alt=""
                  style={{ height: "80px", width: "80px" }}
                />{" "}
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>

        <section className="content-inner bg-light icon-section section-wrapper2">
          <div className="container p-3">
            <h1 id="Token" className="title text-center">
              TOKENOMICS
            </h1>
            <div className="line2 mb-5"></div>

            <div className="container m-3 text-center">
              <div className="row">
                <div className="col">
                  <img
                    src="/images/BEP20-token.svg"
                    alt="Image not found."
                    width="30%"
                  />
                  <h4> BEP-20</h4>
                </div>
                <div className="col">
                  <img
                    src={TenBillion}
                    className="setTriliion"
                    width="30%"
                    alt="Image not found."
                  />
                  <h4> 10,000,000,000 BTYC</h4>
                </div>
              </div>

              <div className="container">
                <p className="container mt-3 text-center fs-5">
                  The total maximum supply of BIGTYCOON tokens is 10,000,000,000
                  (BTYC).
                </p>
                <p className="container text-center fs-5">
                  BIGTYCOON tokens are based on the BEP20 smart contract
                  standard and run on the Binance Smart Chain.
                </p>
              </div>

              <div className="text-center d-flex justify-content-center">
                <img
                  src={pieChart}
                  className="classForExternal"
                  width="90%"
                  alt="Image not found."
                />
              </div>
            </div>
          </div>
        </section>

        <section
          className="content-inner bg-white blog-wrapper"
          id="faqsection"
        >
          <h1 id="road" className="title text-center p-3">
            ROADMAP
          </h1>
          <div className="line2"></div>
          <Roadmap />
        </section>

        <section
          className="content-inner bg-white blog-wrapper"
          id="faqsection"
        >
          <img className="bg-shape1" src={Shape1} alt="" />
          <div className="container">
            <div className="row align-items-center">
              <Faq data={faqData} styles={styles} config={config} />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default Home;
