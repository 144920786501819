import React from "react";
import { Link } from "react-router-dom";

//images
import shape1 from "./../assets/images/home-banner/shape1.png";
import bgimage from "./../assets/images/background/bg2.png";
import flags from "./../assets/images/footer/world-map-with-flags1.png";
import LogoBigTyLight from "./../assets/images/bigtycoon_images/BigTycoonlogo.png";

function Footer() {
  return (
    <>
      <footer className="site-footer style-1" id="footer">
        <img className="bg-shape1" src={shape1} alt="" />
        <div
          className="footer-top background-luminosity"
          style={{ backgroundImage: "url(" + bgimage + ")" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-md-12">
                <div className="widget widget_about">
                  <div className="footer-logo logo-white logo-header">
                    <Link to={"/"}>
                      <img src={LogoBigTyLight} alt="" />
                    </Link>
                  </div>
                  <p>
                    BigTycoon's tailored financial solutions and earning
                    opportunities let users earn based on their interests.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-4 col-6">
                <div className="widget widget_services">
                  <h4 className="widget-title">Support</h4>
                  <ul>
                    <li>
                      <a target="blank" href="/PrivacyPolicy">
                        Privacy
                      </a>
                    </li>
                    <li>
                      <a target="blank" href="/TermsAndConditions">
                        Terms & Conditions
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-4 col-6">
                <div className="widget widget_services">
                  <h4 className="widget-title">Other Links</h4>
                  <ul>
                    <li>
                      <a target="blank" href="https://coinmarketcap.com/">
                        CoinMarketCap
                      </a>
                    </li>
                    <li>
                      <a
                        target="blank"
                        href="https://bscscan.com/token/0x42dABca1aF369FBd9e8Ea286dAFBA45b23fC92D9"
                      >
                        Explorer
                      </a>
                    </li>
                    <li>
                      <a
                        target="blank"
                        href="/btyc_WhitePaper.pdf"
                      >
                        Whitepaper
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="widget recent-posts-entry">
                  <h4 className="widget-title">Community</h4>
                  <div className="dz-social-icon transparent space-10">
                    <ul className="socialicons">
                      <li>
                        <a
                          target="_blank"
                          className="far fa-file-alt"
                          href="https://bscscan.com/token/0x42dABca1aF369FBd9e8Ea286dAFBA45b23fC92D9"
                        ></a>
                      </li>{" "}
                      <li>
                        <a
                          target="_blank"
                          className="fab fa-instagram"
                          href="https://www.instagram.com/bigtycoon__/"
                        ></a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          className="fab fa-facebook"
                          href="https://www.facebook.com/Bigtycoon3/"
                        ></a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          className="fab fa-twitter"
                          href="https://twitter.com/BigTycoon__"
                        ></a>
                      </li>{" "}
                      <li>
                        <a
                          target="_blank"
                          className="far fa-file-alt"
                          href="/btyc_WhitePaper.pdf"
                        ></a>
                      </li>{" "}
                      <li>
                        <a
                          target="_blank"
                          className="fab fa-linkedin"
                          href="https://www.linkedin.com/company/big-tycoon/"
                        ></a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          className="fab fa-reddit"
                          href="https://www.reddit.com/user/bigtycoon__"
                        ></a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          className="fab fa-telegram"
                          href="https://t.me/thebigtycoon"
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom text-center">
          <div className="container">
            <span className="copyright-text">
              Copyright © 2023{" "}
              <a href="#" target="_blank">
                BigTycoon
              </a>
              . All rights reserved.
            </span>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;
