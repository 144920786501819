import React from "react";
import "../css/roadmap.css";
import bigtycoin from "../assets/images/bigty.png";

const ChartRoadmap = () => {
  const milestones = [
    {
      title: "Phase 1",
      date: "Q-4 2020",
      description: "Idea & Concept; Concept Creation"
    },
    {
      title: "Phase 2",
      date: "Q-1 2021",
      description: "Market Analysis & Research"
    },
    {
      title: "Phase 3",
      date: "Q-2 2021",
      description: "Concept, Plan, Team Assembling"
    },
    { title: "Phase 4", date: "Q-3 2021", description: "Website Designing" },
    {
      title: "Phase 5",
      date: "Q-4 2021",
      description: "Website Testing & Ecosystem Design"
    },
    { title: "Phase 6", date: "Q-1 2022", description: "Preselling" },
    {
      title: "Phase 7",
      image: { bigtycoin },
      date: "",
      description: "Launch Date of BTYC Token 19th MAY, 2022"
    },
    {
      title: "Phase 8",
      date: "Q-2 2022",
      description: "Launch of BTYC on Pancakeswap & Official Site of BTYC"
    },
    { title: "Phase 9", date: "Q-3 2022", description: "Listing of EXCHANGE" },
    {
      title: "Phase 10",
      date: "Q-1 2023",
      description:
        "Targeting about 10000 HOLDERS, 15000 TWITTER FOLLOWERS, & 15000 TELEGRAM MEMBERS; Development of Project Rebranding & Improved Plan, Application Development, Research Work and Acquisitions: Market Campaigns & Bounties; Publications & Audience Making: Listing on Coin Gecko, Coinmarketcap, Topic & Coins Bit Exchange Platforms"
    },
    {
      title: "Phase 11",
      date: "Q-2 2023",
      description:
        "Targeting about 25000 HOLDERS, 50000 TWITTER FOLLOWERS, & 50000 TELEGRAM MEMBERS, Launching at Platforms of MULTY TOKEN/COIN STAKING: NFT Design & Develop the NFT Marketplace; Developing E-COMMERCE Portal: Targeting the Launching of BTYC Token on One of the Top 10 Centralized Exchanges."
    },
    {
      title: "Phase 12",
      date: "Q-3 2023",
      description:
        "Targeting about 50000 HOLDERS, 75000 TWITTER FOLLOWERS, & 75000 TELEGRAM MEMBERS, NFT LAUNCH: E COMMERCE Portal Launch Targeting the Launching of BTYC Token on One of PORTAL Launch on One of the Top 5 Centralized Exchange Platforms."
    },
    {
      title: "Phase 13",
      date: "Q-4 2023",
      description:
        "Targeting about 100000 HOLDERS, 150000 TWITTER FOLLOWERS, & 150000 TELEGRAM: Getting the Development of Gaming Portal and Its Launch."
    },
    {
      title: "Phase 14",
      date: "Q-1 2024",
      description:
        "Targeting about 120000 HOLDERS, 175000 TWITTER FOLLOWERS, & 175000 TELEGRAM: Targeting to Launch Our Own Blockchain; Designing Metaverse and its Development."
    }
  ];

  return (
    <ul class="timeline">
      {milestones.map((milestone, index) =>
        index % 2 === 0 ? (
          <li>
            <div class="direction-r">
              <div class="flag-wrapper">
                <span class="flag">{milestone?.title}</span>
                {milestone.date && (
                  <span class="time-wrapper">
                    <span class="time">{milestone?.date}</span>
                  </span>
                )}
              </div>
              <div class="desc">{milestone?.description} </div>
            </div>
          </li>
        ) : (
          <li>
            <div class="direction-l">
              <div class="flag-wrapper">
                <span class="flag">{milestone?.title}</span>
                <span class="time-wrapper">
                  <span class="time">{milestone?.date}</span>
                </span>
              </div>
              <div class="desc">{milestone?.description} </div>
            </div>
          </li>
        )
      )}
    </ul>
  );
};

export default ChartRoadmap;
